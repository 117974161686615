import getUsersLanguage from "../../langaugeUtil";

import './Error.css';

const ErrorPage = () => {

  const userLanguage = getUsersLanguage();

  return (
    <div className="error-container">
      <h1>
        {userLanguage === "PL"
          ? "Wystąpił błąd w trakcie wczytywania strony. Spróbuj ponownie później, lub skontaktuj się z nami pod adresem email biuro@emkaa.pl"
          : "An error has occured during loading of the page. Try again later, or contact us at biuro@emkaa.pl"}
      </h1>
      <svg xmlns="http://www.w3.org/2000/svg" width="220" height="220" viewBox="0 0 98.667 114.444">
        <g id="Group_2" data-name="Group 2" transform="translate(-621 -817)">
          <path id="Path_19" data-name="Path 19"
                d="M94.439.011,3.665,6.495A3.947,3.947,0,0,0,0,10.431v6.746a3.946,3.946,0,0,0,3.665,3.936L94.439,27.6a3.946,3.946,0,0,0,4.228-3.937V3.947A3.947,3.947,0,0,0,94.439.011"
                transform="translate(621 817)" fill="#242425"/>
          <path id="Path_20" data-name="Path 20"
                d="M94.439,100.829,3.665,107.313A3.947,3.947,0,0,0,0,111.249V118a3.947,3.947,0,0,0,3.665,3.937l90.774,6.483a3.946,3.946,0,0,0,4.228-3.937V104.765a3.947,3.947,0,0,0-4.228-3.937"
                transform="translate(621 786.367)" fill="#242425"/>
          <path id="Path_21" data-name="Path 21"
                d="M4.228,50.629,95,57.113a3.947,3.947,0,0,1,3.665,3.937v6.746A3.946,3.946,0,0,1,95,71.731L4.228,78.215A3.946,3.946,0,0,1,0,74.278V54.565a3.947,3.947,0,0,1,4.228-3.937"
                transform="translate(621 801.62)" fill="#242425"/>
          <path id="Path_22" data-name="Path 22"
                d="M5.093,160.431h-3.4a1.2,1.2,0,0,0,1.286.9,1.521,1.521,0,0,0,1.128-.434l.693.752a2.353,2.353,0,0,1-1.854.726,2.331,2.331,0,0,1-2.562-2.313,2.286,2.286,0,0,1,2.4-2.313,2.223,2.223,0,0,1,2.337,2.329c0,.1-.016.242-.024.35m-3.415-.76H3.891a1.067,1.067,0,0,0-1.1-.935,1.063,1.063,0,0,0-1.111.935"
                transform="translate(620.883 769.068)" fill="#242425"/>
          <path id="Path_23" data-name="Path 23"
                d="M18.269,159.739v2.572h-1.3v-2.371c0-.727-.326-1.06-.852-1.06-.584,0-.993.375-.993,1.177v2.254h-1.3v-2.371c0-.727-.308-1.06-.851-1.06-.576,0-.985.375-.985,1.177v2.254h-1.3v-4.492h1.244v.518a1.817,1.817,0,0,1,1.411-.584,1.674,1.674,0,0,1,1.477.734,2.021,2.021,0,0,1,1.62-.734,1.74,1.74,0,0,1,1.837,1.987"
                transform="translate(617.755 769.068)" fill="#242425"/>
          <path id="Path_24" data-name="Path 24"
                d="M27.441,159.835l-.626.618V161.6h-1.3v-6.2h1.3v3.507l1.9-1.8h1.553l-1.87,1.9,2.037,2.588H28.86Z"
                transform="translate(613.248 769.782)" fill="#242425"/>
          <path id="Path_25" data-name="Path 25"
                d="M39.4,159.747v2.563H38.184v-.56a1.474,1.474,0,0,1-1.369.627c-1.051,0-1.678-.585-1.678-1.361,0-.793.56-1.344,1.929-1.344H38.1c0-.559-.333-.885-1.035-.885a2.159,2.159,0,0,0-1.294.418l-.468-.911a3.458,3.458,0,0,1,1.937-.542c1.369,0,2.162.634,2.162,2m-1.3,1.144v-.459h-.893c-.609,0-.8.226-.8.526,0,.326.276.542.735.542a.959.959,0,0,0,.96-.609"
                transform="translate(610.324 769.068)" fill="#242425"/>
          <path id="Path_26" data-name="Path 26"
                d="M49.05,159.747v2.563H47.831v-.56a1.474,1.474,0,0,1-1.369.627c-1.052,0-1.678-.585-1.678-1.361,0-.793.56-1.344,1.929-1.344h1.035c0-.559-.333-.885-1.035-.885a2.159,2.159,0,0,0-1.294.418l-.468-.911a3.458,3.458,0,0,1,1.937-.542c1.369,0,2.162.634,2.162,2m-1.3,1.144v-.459h-.893c-.609,0-.8.226-.8.526,0,.326.276.542.735.542a.959.959,0,0,0,.96-.609"
                transform="translate(607.393 769.068)" fill="#242425"/>
          <path id="Path_27" data-name="Path 27"
                d="M63.9,159.747v2.563H62.677v-.56a1.474,1.474,0,0,1-1.369.627c-1.052,0-1.678-.585-1.678-1.361,0-.793.56-1.344,1.929-1.344h1.035c0-.559-.333-.885-1.035-.885a2.159,2.159,0,0,0-1.294.418l-.468-.911a3.458,3.458,0,0,1,1.937-.542c1.369,0,2.162.634,2.162,2m-1.3,1.144v-.459H61.7c-.609,0-.8.226-.8.526,0,.326.276.542.735.542a.959.959,0,0,0,.96-.609"
                transform="translate(602.882 769.068)" fill="#242425"/>
          <path id="Path_28" data-name="Path 28"
                d="M72.486,157.752v1.2c-.108-.008-.191-.017-.292-.017A1.1,1.1,0,0,0,71,160.19v2.121H69.7v-4.492h1.244v.592a1.815,1.815,0,0,1,1.545-.659"
                transform="translate(599.823 769.068)" fill="#242425"/>
          <path id="Path_29" data-name="Path 29"
                d="M76.527,160.065a2.322,2.322,0,0,1,2.5-2.313,2.062,2.062,0,0,1,2.012,1.144l-1.01.542a1.12,1.12,0,0,0-1.01-.617,1.246,1.246,0,0,0,0,2.488,1.1,1.1,0,0,0,1.01-.618l1.01.551a2.083,2.083,0,0,1-2.012,1.135,2.322,2.322,0,0,1-2.5-2.313"
                transform="translate(597.747 769.068)" fill="#242425"/>
          <path id="Path_30" data-name="Path 30"
                d="M90.887,159.024V161.6h-1.3v-2.371c0-.727-.334-1.06-.911-1.06-.626,0-1.077.384-1.077,1.21V161.6h-1.3v-6.2h1.3v2.171a1.974,1.974,0,0,1,1.428-.534,1.761,1.761,0,0,1,1.862,1.987"
                transform="translate(594.78 769.782)" fill="#242425"/>
          <path id="Path_31" data-name="Path 31"
                d="M96.6,155.588a.745.745,0,0,1,.81-.727.731.731,0,0,1,.81.7.813.813,0,0,1-1.619.025m.158,1.353h1.3v4.492h-1.3Z"
                transform="translate(591.648 769.946)" fill="#242425"/>
          <path id="Path_32" data-name="Path 32"
                d="M105.35,161.688a1.86,1.86,0,0,1-1.06.284,1.481,1.481,0,0,1-1.678-1.612v-1.845h-.693v-1h.693V156.42h1.3v1.094h1.119v1h-1.119v1.828a.524.524,0,0,0,.559.593.877.877,0,0,0,.526-.167Z"
                transform="translate(590.032 769.472)" fill="#242425"/>
          <path id="Path_33" data-name="Path 33"
                d="M114.175,160.431h-3.4a1.2,1.2,0,0,0,1.286.9,1.52,1.52,0,0,0,1.127-.434l.693.752a2.623,2.623,0,0,1-4.416-1.587,2.287,2.287,0,0,1,2.4-2.313,2.223,2.223,0,0,1,2.337,2.329c0,.1-.015.242-.024.35m-3.414-.76h2.212a1.121,1.121,0,0,0-2.212,0"
                transform="translate(587.739 769.068)" fill="#242425"/>
          <path id="Path_34" data-name="Path 34"
                d="M121.691,159.835l-.626.618V161.6h-1.3v-6.2h1.3v3.507l1.9-1.8h1.553l-1.87,1.9,2.037,2.588H123.11Z"
                transform="translate(584.611 769.782)" fill="#242425"/>
          <path id="Path_35" data-name="Path 35"
                d="M129.17,160.065a2.322,2.322,0,0,1,2.5-2.313,2.062,2.062,0,0,1,2.012,1.144l-1.01.542a1.12,1.12,0,0,0-1.01-.617,1.246,1.246,0,0,0,0,2.488,1.1,1.1,0,0,0,1.01-.618l1.01.551a2.083,2.083,0,0,1-2.012,1.135,2.322,2.322,0,0,1-2.5-2.313"
                transform="translate(581.752 769.068)" fill="#242425"/>
          <path id="Path_36" data-name="Path 36"
                d="M138.758,155.588a.745.745,0,0,1,.81-.727.731.731,0,0,1,.81.7.813.813,0,0,1-1.619.025m.159,1.353h1.3v4.492h-1.3Z"
                transform="translate(578.839 769.946)" fill="#242425"/>
        </g>
      </svg>
    </div>
  )
}

export default ErrorPage;
import LogoBars from "./components/LogoBars/LogoBars";
import "./About.css";
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import LoadingPage from "../util/Loading";
import {useMediaQuery} from "react-responsive";
import ClientInterface from "../../interfaces/ClientInterface";
import Carousel from "./components/Carousel/Carousel";
import getUsersLanguage from "../../langaugeUtil";
import getText from "../../staticText";
import {useEffect, useState} from "react";

function About() {
  const [isFakeLoading, setIsFakeLoading] = useState(false);

  const isFirstLaunch = !localStorage.getItem("site_language");

  useEffect(() => {
    if(isFirstLaunch) {
      setIsFakeLoading(true);
      console.log('set false loading to true')
      setTimeout(() => {
        setIsFakeLoading(false); console.log('disabled false loading');
      }, 2000);
    }
  }, []);

  const language = getUsersLanguage().toLowerCase();
  const TEXT = getText(language);

  const {isLoading, error, data, isFetched} =
    useQuery({
      queryKey: ["aboutUs"],
      queryFn: async () => {
        const response = await axios.get(
          //query generated from https://docs.strapi.io/dev-docs/api/rest/interactive-query-builder
          `/api/text?populate[bottom_image][fields][0]=url&locale=${language}`
        );
        return response.data.data; //"data" field from strapi response
      }
    });

  const {isLoading: isLoadingClients, error: errorClients, data: dataClients, isFetched: isFetchedClients} =
    useQuery<ClientInterface[]>({
      queryKey: ["customers"],
      queryFn: async () => {
        const response = await axios.get(
          //query generated from https://docs.strapi.io/dev-docs/api/rest/interactive-query-builder
          `/api/clients?populate[logo][fields][0]=url&sort=rank:asc&locale=${language}`
        );
        return response.data.data; //"data" field from strapi response
      }
    });
  const isPhone = useMediaQuery({query: '(max-width: 768px) or (max-height: 500px)'});

  const baseUrl = axios.defaults.baseURL;

  if(isLoading || isLoadingClients || isFakeLoading){
    return (
      <LoadingPage />
    )
  }

//TODO fonts in some places are wrong/cropped
  return (
    <div className="main-content">
      {isPhone ? <Carousel/>: <LogoBars/>}
      <div className="section">
        <div className="left-section">
          <p>{TEXT.ABOUT_US}</p>
        </div>
        <div className="right-section">
          <div className="about">
            <p>
              {data.about_us}
            </p>
          </div>
        </div>

      </div>
      <div className="image-section">
        <img
          src={`${baseUrl}${data.bottom_image.url}`}
          id="sectionImage"
          alt="Background"
          className="section-image"
        />
        <div className="image-section-background-filter"/>
        <div className="image-section-text-container">
          <svg xmlns="http://www.w3.org/2000/svg" width="877.421" height="543.118" viewBox="0 0 877.421 543.118">
            <g id="Group_2523" data-name="Group 2523" transform="translate(-901.579 -2721)">
              <text id="działamy_już_od" data-name="działamy już od" transform="translate(901.579 3249.781)" fill="#fff"
                    fontSize="55" fontFamily="SegoeUIBlack, Segoe UI">
                <tspan x="0" y="0">{TEXT.WORKING_SINCE}</tspan>
              </text>
              <text id="roku" transform="translate(1620 3250.118)" fill="#fff" fontSize="55"
                    fontFamily="SegoeUIBlack, Segoe UI">
                <tspan x="0" y="0">{TEXT.YEAR}</tspan>
              </text>
              <text id="_2007" data-name="2007" transform="translate(1585.579 3263) rotate(-90)" fill="#fff"
                    fontSize="226" fontFamily="SegoeUIBlack, Segoe UI">
                <tspan x="0" y="0">2007</tspan>
              </text>
            </g>
          </svg>
        </div>
      </div>
      <div className="logos-section">
        <h2>{TEXT.CLIENTS}</h2>
        <div className="logos-container">
          {dataClients?.map((client, i) => {
            return (<a key={`client#${i}`} href={client.website} target={"_blank"} rel="noreferrer"><img src={`${baseUrl}${client.logo.url}`} alt={client.name}/></a>);
          })}
        </div>
      </div>
    </div>
  );
}

export default About;

import {NavLink, useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";

import styles from "./Project.module.css"
import getUsersLanguage from "../../../langaugeUtil";
import LoadingPage from "../../util/Loading";
import axios from "axios";
import {useEffect, useState} from "react";
import ProjectInterface from "../../../interfaces/ProjectInterface";
import {log} from "console";
import BottomNav from "../../../components/BottomNav";
import getText from "../../../staticText";

const Project = () => {
  const {id} = useParams();

  const language = getUsersLanguage().toLowerCase();
  const TEXT = getText(language);

  const [project, setProject] = useState<ProjectInterface>();
  const baseUrl = axios.defaults.baseURL;

  const {isLoading, error, data, isFetching} =
    useQuery({
      queryKey: ["projectId", id],
      queryFn: async () => {
        const response = await axios.get(
          `/api/projects/${id}?populate=*&locale=${language}`
        );
        if (response.data.data) setProject(response.data.data);
        return response.data.data;
      }
    });

  const [prevProjectId, setPrevProjectId] = useState<string | null>(null);
  const [nextProjectId, setNextProjectId] = useState<string | null>(null);

  let queryKey = `project-list-${id}`
  const {data: projectList} =
    useQuery({
      queryKey: [queryKey],
      queryFn: async () => {
        const res = await axios.get(
          //query generated from https://docs.strapi.io/dev-docs/api/rest/interactive-query-builder
          `/api/projects?sort[0]=id:asc&fields[0]=id&fields[1]=documentId&locale=${language}`
        )

        const currentIndex = res.data.data.findIndex((item: any) => item.documentId === id);

        if (currentIndex !== -1) {
          const before = currentIndex > 0 ? res.data.data[currentIndex - 1].documentId : null;
          const after = currentIndex < res.data.data.length - 1 ? res.data.data[currentIndex + 1].documentId : null;

          if (before) {
            setPrevProjectId(before);
          } else {
            setPrevProjectId(null);
          }
          if (after) {
            setNextProjectId(after)
          } else {
            setNextProjectId(null);
          }
        }
        return res.data.data;
      }
    });


  if (isLoading) {
    return (
      <LoadingPage/>
    )
  }

  if (!project) <LoadingPage/>


  return (
    <div className={styles.main_content}>
      <section className={styles.section_full}>
        <div className={styles.container}>
          <img src={`${baseUrl}${project?.Images[0].url}`} alt="Projekt"/>
          <div className={styles.info_bar}>
            <div className={styles.info_item}>
              <span className={styles.label}>{TEXT.CATEGORY}</span>
              <span className={styles.value}>{project?.Category}</span>
            </div>
            <div className={styles.info_item}>
              <span className={styles.label}>{TEXT.LOCATION}</span>
              <span className={styles.value}>{project?.Location}</span>
            </div>
            <div className={styles.info_item}>
              <span className={styles.label}>{TEXT.YEAR_2}</span>
              <span className={styles.value}>{project?.Year}</span>
            </div>
            <div className={styles.info_item}>
              <span className={styles.label}>{TEXT.CLIENT}</span>
              <span className={styles.value}>{project?.Client}</span>
            </div>
          </div>
        </div>
      </section>
      <NavLink className={styles.arrow} to="/projects">
        <svg  xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 65.37 98.01">
          <polyline className={styles.st0} points="58.05 5.7 4.67 49.01 58.05 92.31"/>
        </svg>
      </NavLink>
      {project && project.Images.length > 1 &&
        (<section className={styles.section}>
          <div className={styles.container}>
            <img src={`${baseUrl}${project?.Images[1].url}`} alt="Projekt"/>
            {project.Quote &&
                <div className={styles["text_overlay"]}>{project.Quote}</div>}
          </div>
        </section>)
      }

      <section className={styles.section_text}>
        <div className={styles["left_section"]}>
          <p className={styles.text_vr}>{TEXT.CONCEPT.toUpperCase()}</p>
        </div>
        <div className={styles["right_section"]}>
          <div className={styles.about}>
            <p>
              {project?.Description}
            </p>
          </div>
        </div>
      </section>

      {project && project.Images.length > 2 &&
        project.Images.slice(2).map((image, index) => {
            return (<section key={index}
                             className={index + 2 === project.Images.length - 2 ? styles.section_full : styles.section}>
                <div className={styles.container}>
                  <img src={`${baseUrl}${image.url}`} alt="Projekt"/>
                </div>
              </section>
            )
          }
        )
      }

      <BottomNav previous={prevProjectId} next={nextProjectId}/>
    </div>
  )
}

export default Project;
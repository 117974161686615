import './Carousel.css';
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import LoadingPage from "../../../util/Loading";
import React from "react";
import getUsersLanguage from '../../../../langaugeUtil';
import getText from '../../../../staticText';

const Carousel = () => {
  const language = getUsersLanguage().toLowerCase();
  const TEXT = getText(language);

  const {isLoading, error, data, isFetched} = useQuery({
    queryKey: ["slideshow"],
    queryFn: async () => {
      const response = await axios.get(
        //query generated from https://docs.strapi.io/dev-docs/api/rest/interactive-query-builder
        `/api/text?populate[slideshow_architecture][fields][0]=url&populate[slideshow_facades][fields][0]=url&populate[slideshow_interiors][fields][0]=url&locale=${language}`
      );
      return response.data.data; //"data" field from strapi response
    }
  });
  const baseUrl = axios.defaults.baseURL;

  if(isLoading){
    return (
      <LoadingPage />
    )
  }
  return (
    <div className="carousel-container">
      <div className="carousel-image-wrapper">
        <img className="carousel-image" src={`${baseUrl}${data.slideshow_architecture.url}`} alt="Obrazek 1"/>
        <div className="carousel-text-overlay">
          <svg xmlns="http://www.w3.org/2000/svg" width="41.634" height="41.265" viewBox="0 0 41.634 41.265">
            <g id="Group_2518" data-name="Group 2518" transform="translate(573.557 -154.745) rotate(90)">
              <path id="Path_19" data-name="Path 19"
                    d="M39.85,0,1.547,2.74A1.665,1.665,0,0,0,0,4.4V7.248A1.665,1.665,0,0,0,1.547,8.909l38.3,2.736a1.665,1.665,0,0,0,1.784-1.661V1.665A1.665,1.665,0,0,0,39.85,0"
                    transform="translate(154.745 573.557) rotate(-90)" fill="#242425"/>
              <path id="Path_20" data-name="Path 20"
                    d="M39.85,0,1.547,2.74A1.665,1.665,0,0,0,0,4.4V7.248A1.666,1.666,0,0,0,1.547,8.909l38.3,2.736a1.665,1.665,0,0,0,1.784-1.661V1.665A1.665,1.665,0,0,0,39.85,0"
                    transform="translate(184.36 573.557) rotate(-90)" fill="#fff"/>
              <path id="Path_21" data-name="Path 21"
                    d="M1.784,0l38.3,2.736A1.665,1.665,0,0,1,41.634,4.4V7.248a1.665,1.665,0,0,1-1.547,1.661l-38.3,2.736A1.665,1.665,0,0,1,0,9.984V1.665A1.665,1.665,0,0,1,1.784,0"
                    transform="translate(169.614 573.557) rotate(-90)" fill="#fff"/>
            </g>
          </svg>
          <span>{TEXT.ARCHITECTURE}</span>
        </div>
      </div>
      <div className="carousel-image-wrapper">
        <img className="carousel-image" src={`${baseUrl}${data.slideshow_facades.url}`} alt="Obrazek 2"/>
        <div className="carousel-text-overlay">
          <svg xmlns="http://www.w3.org/2000/svg" width="41.634" height="41.265" viewBox="0 0 41.634 41.265">
            <g id="Group_2519" data-name="Group 2519" transform="translate(573.557 -154.745) rotate(90)">
              <path id="Path_19" data-name="Path 19"
                    d="M39.85,0,1.547,2.74A1.665,1.665,0,0,0,0,4.4V7.248A1.665,1.665,0,0,0,1.547,8.909l38.3,2.736a1.665,1.665,0,0,0,1.784-1.661V1.665A1.665,1.665,0,0,0,39.85,0"
                    transform="translate(154.745 573.557) rotate(-90)" fill="#fff"/>
              <path id="Path_20" data-name="Path 20"
                    d="M39.85,0,1.547,2.74A1.665,1.665,0,0,0,0,4.4V7.248A1.666,1.666,0,0,0,1.547,8.909l38.3,2.736a1.665,1.665,0,0,0,1.784-1.661V1.665A1.665,1.665,0,0,0,39.85,0"
                    transform="translate(184.36 573.557) rotate(-90)" fill="#fff"/>
              <path id="Path_21" data-name="Path 21"
                    d="M1.784,0l38.3,2.736A1.665,1.665,0,0,1,41.634,4.4V7.248a1.665,1.665,0,0,1-1.547,1.661l-38.3,2.736A1.665,1.665,0,0,1,0,9.984V1.665A1.665,1.665,0,0,1,1.784,0"
                    transform="translate(169.614 573.557) rotate(-90)" fill="#242425"/>
            </g>
          </svg>
          <span>{TEXT.FACADES}</span>
        </div>
      </div>
      <div className="carousel-image-wrapper">
        <img className="carousel-image" src={`${baseUrl}${data.slideshow_interiors.url}`} alt="Obrazek 3"/>
        <div className="carousel-text-overlay">
          <svg xmlns="http://www.w3.org/2000/svg" width="41.634" height="41.265" viewBox="0 0 41.634 41.265">
            <g id="Group_2520" data-name="Group 2520" transform="translate(573.557 -154.745) rotate(90)">
              <path id="Path_19" data-name="Path 19"
                    d="M39.85,0,1.547,2.74A1.665,1.665,0,0,0,0,4.4V7.248A1.665,1.665,0,0,0,1.547,8.909l38.3,2.736a1.665,1.665,0,0,0,1.784-1.661V1.665A1.665,1.665,0,0,0,39.85,0"
                    transform="translate(154.745 573.557) rotate(-90)" fill="#fff"/>
              <path id="Path_20" data-name="Path 20"
                    d="M39.85,0,1.547,2.74A1.665,1.665,0,0,0,0,4.4V7.248A1.666,1.666,0,0,0,1.547,8.909l38.3,2.736a1.665,1.665,0,0,0,1.784-1.661V1.665A1.665,1.665,0,0,0,39.85,0"
                    transform="translate(184.36 573.557) rotate(-90)" fill="#242425"/>
              <path id="Path_21" data-name="Path 21"
                    d="M1.784,0l38.3,2.736A1.665,1.665,0,0,1,41.634,4.4V7.248a1.665,1.665,0,0,1-1.547,1.661l-38.3,2.736A1.665,1.665,0,0,1,0,9.984V1.665A1.665,1.665,0,0,1,1.784,0"
                    transform="translate(169.614 573.557) rotate(-90)" fill="#fff"/>
            </g>
          </svg>
          <span>{TEXT.INTERIORS}</span>
        </div>
      </div>
    </div>
  )
};

export default Carousel;
import './Contact.css';
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import LoadingPage from '../util/Loading';
import getUsersLanguage from '../../langaugeUtil';
import getText from '../../staticText';
import TeamMember from "../../interfaces/TeamMemberInterface";
import TeamMemberCard from "../team/TeamMemberCard/TeamMemberCard";

const Contact = () => {

  const language = getUsersLanguage().toLowerCase();
  const TEXT = getText(language);

  const {isLoading, error, data, isFetched} =
    useQuery({
      queryKey: ["footer"],
      queryFn: async () => {
        const response = await axios.get(
          //query generated from https://docs.strapi.io/dev-docs/api/rest/interactive-query-builder
          `/api/text?locale=${language}`
        );
        return response.data.data; //"data" field from strapi response
      }
    });

  if (isLoading) {
    return (
      <LoadingPage/>
    )
  }

  return (
    <div className="contact-container">
      <div className="contact-content-header">
        <p>{TEXT.CONTACT.toUpperCase()}</p>
      </div>
      <div className="contact-content">
        <div className="brand">
          <svg xmlns="http://www.w3.org/2000/svg" width="220" height="220" viewBox="0 0 98.667 114.444">
            <g id="Group_2" data-name="Group 2" transform="translate(-621 -817)">
              <path id="Path_19" data-name="Path 19"
                    d="M94.439.011,3.665,6.495A3.947,3.947,0,0,0,0,10.431v6.746a3.946,3.946,0,0,0,3.665,3.936L94.439,27.6a3.946,3.946,0,0,0,4.228-3.937V3.947A3.947,3.947,0,0,0,94.439.011"
                    transform="translate(621 817)" fill="#242425"/>
              <path id="Path_20" data-name="Path 20"
                    d="M94.439,100.829,3.665,107.313A3.947,3.947,0,0,0,0,111.249V118a3.947,3.947,0,0,0,3.665,3.937l90.774,6.483a3.946,3.946,0,0,0,4.228-3.937V104.765a3.947,3.947,0,0,0-4.228-3.937"
                    transform="translate(621 786.367)" fill="#242425"/>
              <path id="Path_21" data-name="Path 21"
                    d="M4.228,50.629,95,57.113a3.947,3.947,0,0,1,3.665,3.937v6.746A3.946,3.946,0,0,1,95,71.731L4.228,78.215A3.946,3.946,0,0,1,0,74.278V54.565a3.947,3.947,0,0,1,4.228-3.937"
                    transform="translate(621 801.62)" fill="#242425"/>
              <path id="Path_22" data-name="Path 22"
                    d="M5.093,160.431h-3.4a1.2,1.2,0,0,0,1.286.9,1.521,1.521,0,0,0,1.128-.434l.693.752a2.353,2.353,0,0,1-1.854.726,2.331,2.331,0,0,1-2.562-2.313,2.286,2.286,0,0,1,2.4-2.313,2.223,2.223,0,0,1,2.337,2.329c0,.1-.016.242-.024.35m-3.415-.76H3.891a1.067,1.067,0,0,0-1.1-.935,1.063,1.063,0,0,0-1.111.935"
                    transform="translate(620.883 769.068)" fill="#242425"/>
              <path id="Path_23" data-name="Path 23"
                    d="M18.269,159.739v2.572h-1.3v-2.371c0-.727-.326-1.06-.852-1.06-.584,0-.993.375-.993,1.177v2.254h-1.3v-2.371c0-.727-.308-1.06-.851-1.06-.576,0-.985.375-.985,1.177v2.254h-1.3v-4.492h1.244v.518a1.817,1.817,0,0,1,1.411-.584,1.674,1.674,0,0,1,1.477.734,2.021,2.021,0,0,1,1.62-.734,1.74,1.74,0,0,1,1.837,1.987"
                    transform="translate(617.755 769.068)" fill="#242425"/>
              <path id="Path_24" data-name="Path 24"
                    d="M27.441,159.835l-.626.618V161.6h-1.3v-6.2h1.3v3.507l1.9-1.8h1.553l-1.87,1.9,2.037,2.588H28.86Z"
                    transform="translate(613.248 769.782)" fill="#242425"/>
              <path id="Path_25" data-name="Path 25"
                    d="M39.4,159.747v2.563H38.184v-.56a1.474,1.474,0,0,1-1.369.627c-1.051,0-1.678-.585-1.678-1.361,0-.793.56-1.344,1.929-1.344H38.1c0-.559-.333-.885-1.035-.885a2.159,2.159,0,0,0-1.294.418l-.468-.911a3.458,3.458,0,0,1,1.937-.542c1.369,0,2.162.634,2.162,2m-1.3,1.144v-.459h-.893c-.609,0-.8.226-.8.526,0,.326.276.542.735.542a.959.959,0,0,0,.96-.609"
                    transform="translate(610.324 769.068)" fill="#242425"/>
              <path id="Path_26" data-name="Path 26"
                    d="M49.05,159.747v2.563H47.831v-.56a1.474,1.474,0,0,1-1.369.627c-1.052,0-1.678-.585-1.678-1.361,0-.793.56-1.344,1.929-1.344h1.035c0-.559-.333-.885-1.035-.885a2.159,2.159,0,0,0-1.294.418l-.468-.911a3.458,3.458,0,0,1,1.937-.542c1.369,0,2.162.634,2.162,2m-1.3,1.144v-.459h-.893c-.609,0-.8.226-.8.526,0,.326.276.542.735.542a.959.959,0,0,0,.96-.609"
                    transform="translate(607.393 769.068)" fill="#242425"/>
              <path id="Path_27" data-name="Path 27"
                    d="M63.9,159.747v2.563H62.677v-.56a1.474,1.474,0,0,1-1.369.627c-1.052,0-1.678-.585-1.678-1.361,0-.793.56-1.344,1.929-1.344h1.035c0-.559-.333-.885-1.035-.885a2.159,2.159,0,0,0-1.294.418l-.468-.911a3.458,3.458,0,0,1,1.937-.542c1.369,0,2.162.634,2.162,2m-1.3,1.144v-.459H61.7c-.609,0-.8.226-.8.526,0,.326.276.542.735.542a.959.959,0,0,0,.96-.609"
                    transform="translate(602.882 769.068)" fill="#242425"/>
              <path id="Path_28" data-name="Path 28"
                    d="M72.486,157.752v1.2c-.108-.008-.191-.017-.292-.017A1.1,1.1,0,0,0,71,160.19v2.121H69.7v-4.492h1.244v.592a1.815,1.815,0,0,1,1.545-.659"
                    transform="translate(599.823 769.068)" fill="#242425"/>
              <path id="Path_29" data-name="Path 29"
                    d="M76.527,160.065a2.322,2.322,0,0,1,2.5-2.313,2.062,2.062,0,0,1,2.012,1.144l-1.01.542a1.12,1.12,0,0,0-1.01-.617,1.246,1.246,0,0,0,0,2.488,1.1,1.1,0,0,0,1.01-.618l1.01.551a2.083,2.083,0,0,1-2.012,1.135,2.322,2.322,0,0,1-2.5-2.313"
                    transform="translate(597.747 769.068)" fill="#242425"/>
              <path id="Path_30" data-name="Path 30"
                    d="M90.887,159.024V161.6h-1.3v-2.371c0-.727-.334-1.06-.911-1.06-.626,0-1.077.384-1.077,1.21V161.6h-1.3v-6.2h1.3v2.171a1.974,1.974,0,0,1,1.428-.534,1.761,1.761,0,0,1,1.862,1.987"
                    transform="translate(594.78 769.782)" fill="#242425"/>
              <path id="Path_31" data-name="Path 31"
                    d="M96.6,155.588a.745.745,0,0,1,.81-.727.731.731,0,0,1,.81.7.813.813,0,0,1-1.619.025m.158,1.353h1.3v4.492h-1.3Z"
                    transform="translate(591.648 769.946)" fill="#242425"/>
              <path id="Path_32" data-name="Path 32"
                    d="M105.35,161.688a1.86,1.86,0,0,1-1.06.284,1.481,1.481,0,0,1-1.678-1.612v-1.845h-.693v-1h.693V156.42h1.3v1.094h1.119v1h-1.119v1.828a.524.524,0,0,0,.559.593.877.877,0,0,0,.526-.167Z"
                    transform="translate(590.032 769.472)" fill="#242425"/>
              <path id="Path_33" data-name="Path 33"
                    d="M114.175,160.431h-3.4a1.2,1.2,0,0,0,1.286.9,1.52,1.52,0,0,0,1.127-.434l.693.752a2.623,2.623,0,0,1-4.416-1.587,2.287,2.287,0,0,1,2.4-2.313,2.223,2.223,0,0,1,2.337,2.329c0,.1-.015.242-.024.35m-3.414-.76h2.212a1.121,1.121,0,0,0-2.212,0"
                    transform="translate(587.739 769.068)" fill="#242425"/>
              <path id="Path_34" data-name="Path 34"
                    d="M121.691,159.835l-.626.618V161.6h-1.3v-6.2h1.3v3.507l1.9-1.8h1.553l-1.87,1.9,2.037,2.588H123.11Z"
                    transform="translate(584.611 769.782)" fill="#242425"/>
              <path id="Path_35" data-name="Path 35"
                    d="M129.17,160.065a2.322,2.322,0,0,1,2.5-2.313,2.062,2.062,0,0,1,2.012,1.144l-1.01.542a1.12,1.12,0,0,0-1.01-.617,1.246,1.246,0,0,0,0,2.488,1.1,1.1,0,0,0,1.01-.618l1.01.551a2.083,2.083,0,0,1-2.012,1.135,2.322,2.322,0,0,1-2.5-2.313"
                    transform="translate(581.752 769.068)" fill="#242425"/>
              <path id="Path_36" data-name="Path 36"
                    d="M138.758,155.588a.745.745,0,0,1,.81-.727.731.731,0,0,1,.81.7.813.813,0,0,1-1.619.025m.159,1.353h1.3v4.492h-1.3Z"
                    transform="translate(578.839 769.946)" fill="#242425"/>
            </g>
          </svg>
        </div>
        <div className="contact-address-container">
          <svg id="Warstwa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 841.89 827.59">
            <rect fill="#303031" width="841.89" height="827.59" rx="1.27" ry="1.27"/>
            <path fill="#fff"
                  d="M540.58,718.95h89.51c25.31.14,45.96-20.23,46.16-45.54v-358.34c.02-14.03-6.49-27.27-17.62-35.81l-207.33-160.84c-16.8-13.03-40.28-13.03-57.08,0l-207.33,160.84c-11.13,8.54-17.64,21.78-17.62,35.81v358.34c.2,25.31,20.85,45.68,46.16,45.54h89.51v-162.58c.04-35.67,28.95-64.58,64.63-64.62h106.38c35.67.03,64.59,28.94,64.63,64.62v162.58Z"/>
          </svg>
          <span className="contact-bolded">emkaa architekci Sp. z o.o.</span>
          <span>{isFetched ? data.address : "ul. Kobielska 23/217, 04-359 Warszawa"}</span>
          <svg id="Warstwa_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 827.58 827.59">
            <path fill="#303031"
                  d="M827.58,414.85C828.17,186.32,643.38.59,414.85,0,186.32-.58.59,184.2,0,412.73c-.58,228.53,184.2,414.27,412.73,414.85,99.75.26,196.23-35.53,271.69-100.77l.18-.17.06-.05.06-.06c90.79-78.09,142.97-191.93,142.87-311.68ZM84.67,414.85c-.48-182.36,146.96-330.58,329.31-331.06,182.36-.48,330.58,146.96,331.06,329.31.2,74.52-24.82,146.92-70.99,205.42-33.86-70.41-95.05-123.9-169.35-148.05,76.57-49.57,98.45-151.83,48.88-228.4-49.57-76.57-151.83-98.45-228.4-48.88-76.57,49.57-98.45,151.83-48.88,228.4,12.82,19.81,29.74,36.64,49.62,49.35-74.26,24.32-135.64,77.46-170.33,147.48-45.89-57.94-70.88-129.67-70.92-203.58Z"/>
          </svg>
          <span>{`Tel. Architektura: ${isFetched ? data.phone_number_architektura : "+48 22 119 14 17"}`}</span>
          <span>{`Tel. Elewacje: ${isFetched ? data.phone_number_elewacje : "+48 22 412 63 00"}`}</span>
          <span>{`e-mail: ${isFetched ? data.email : "biuro@emkaa.pl"}`}</span>
        </div>
        <div className="contact-links">
          <a href="https://linkedin.com/company/emkaa-architekci" target="_blank" rel="noreferrer">
            <svg id="Warstwa_11" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 90.2 80.7"
                 fill="#303031">
              <path fill="#303031"
                    d="M68.9,12.77c2.45.54,4.28,2.37,4.82,4.82v47.72c-.55,2.5-2.52,4.49-5.1,4.82-15.99-.11-32.04.22-48-.17-2.27-.65-4.03-2.66-4.26-5.04V17.87c.19-2.34,2.5-4.8,4.82-5.1h47.72ZM32.9,22c-2.5-2.5-7.18-1.62-8.42,1.76-1.58,4.33,2.44,8.34,6.77,6.66,3.37-1.3,4.16-5.9,1.64-8.42ZM65.09,61.51v-17.31c0-1.02-.87-3.18-1.41-4.08-3.37-5.63-10.72-6.72-15.23-1.69-.21.24-.31.7-.62.67v-3.36h-8.51v25.77h8.51v-15.18c0-2.25,2.19-4.35,4.43-4.32s4.31,2.12,4.31,4.32v15.18h8.51ZM33.5,35.74h-8.51v25.77h8.51v-25.77Z"/>
            </svg>
          </a>
          <a href="https://www.facebook.com/emkaarchitekci" target="_blank" rel="noreferrer">
            <svg id="Warstwa_12" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 90.2 80.7"
                 fill="#303031">
              <path fill="#303031"
                    d="M66.61,13.27H23.59c-3.96,0-7.17,3.16-7.17,7.05v42.29c0,3.89,3.21,7.05,7.17,7.05h23.47v-24.19h-3.75v-7.66h3.75v-4.88c0-6.39,2.49-10.19,9.58-10.19h7.18v7.63h-4.18c-2.75,0-2.94,1.09-2.94,3.14v4.31s6.56,0,6.56,0l-.79,7.66h-5.78v24.19h9.91c3.96,0,7.17-3.15,7.17-7.05V20.32c0-3.89-3.21-7.05-7.17-7.05"/>
            </svg>
          </a>
          <a href="https://www.instagram.com/emkaa_architekci" target="_blank" rel="noreferrer">
            <svg id="Warstwa_13" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 90.2 80.7"
                 fill="#303031">
              <path fill="#303031"
                    d="M57.71,69.18h-25.22c-8.83,0-16.01-6.95-16.01-15.5v-24.42c0-8.55,7.18-15.5,16.01-15.5h25.22c8.82,0,16.01,6.95,16.01,15.5v24.42c0,8.54-7.18,15.5-16.01,15.5M32.49,18.65c-6.04,0-10.97,4.76-10.97,10.62v24.42c0,5.85,4.92,10.62,10.97,10.62h25.22c6.04,0,10.96-4.76,10.96-10.62v-24.42c0-5.85-4.92-10.62-10.96-10.62h-25.22Z"/>
              <path fill="#303031"
                    d="M45.1,54.37c-7.34,0-13.32-5.79-13.32-12.9s5.98-12.89,13.32-12.89,13.32,5.78,13.32,12.89-5.97,12.9-13.32,12.9M45.1,33.46c-4.56,0-8.27,3.59-8.27,8.01s3.71,8.01,8.27,8.01,8.27-3.59,8.27-8.01-3.71-8.01-8.27-8.01"/>
              <path fill="#303031"
                    d="M63.3,25.94c0,1.98-1.65,3.58-3.7,3.58s-3.7-1.6-3.7-3.58,1.66-3.58,3.7-3.58,3.7,1.6,3.7,3.58"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Contact;
import {useState} from "react";
import "./Team.css"
import TeamMemberCard from "./TeamMemberCard/TeamMemberCard";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import LoadingPage from "../util/Loading";
import TeamMember from "../../interfaces/TeamMemberInterface";
import getUsersLanguage from "../../langaugeUtil";
import getText from "../../staticText";

const Team = () => {

  const language = getUsersLanguage().toLowerCase();
  const TEXT = getText(language);

  const {isLoading, error, data, isFetched} =
  useQuery({
    queryKey: ["team"],
    queryFn: async () => {
      const response = await axios.get(
        //query generated from https://docs.strapi.io/dev-docs/api/rest/interactive-query-builder
        `/api/team-members?populate=*&sort=rank:asc&locale=${language}`
      );
      return response.data.data; //"data" field from strapi response
    }
  });

  if(isLoading){
    return (
      <LoadingPage />
    )
  }



  return (
    <div className="team-container">
      <div className="team-section">
        <div className="team-section-header">
          <p>{TEXT.TEAM.toUpperCase()}</p>
        </div>
        <div className="team-members">
          {data && data.map((p: TeamMember, i: number) => (
            <TeamMemberCard key={`teamMember#${i}`} member={p}/>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Team;
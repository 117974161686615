import axios from "axios";
import ProjectInterface from "../../../interfaces/ProjectInterface";
import "./ProjectCard.css"
import { redirect, useNavigate } from "react-router-dom";
import getUsersLanguage from "../../../langaugeUtil";
import getText from "../../../staticText";

interface Props {
    project: ProjectInterface
}

const ProjectCard = (props: Props) => {

    const language = getUsersLanguage().toLowerCase();
    const TEXT = getText(language);

    const project = props.project;
    const navigate = useNavigate();
    
    const baseUrl = axios.defaults.baseURL;

    const onProjectClick = () => {
        if(project.id) navigate(`/projects/${project.documentId}`)
    }

    return (
        <div className="panel" onClick={onProjectClick}>
            <img src={`${baseUrl}${project.Images[0].url}`} alt="Projekt 1" />
            <div className="info-bar">
                <div className="info-bar-header">
                    <h2><br/></h2>
                    <h2 className="czary-mary">{project.Title}</h2>
                </div>
                <div className="info-bar-content">
                    <div className="info-item">
                        <span className="category-field">{TEXT.CATEGORY}</span>
                        <span className="value">{project.Category}</span>
                    </div>
                    <div className="info-item">
                        <span className="category-field">{TEXT.LOCATION}</span>
                        <span className="value">{project.Location}</span>
                    </div>
                    <div className="info-item">
                        <span className="category-field">{TEXT.YEAR_2}</span>
                        <span className="value">{project.Year}</span>
                    </div>
                    <div className="info-item">
                        <span className="category-field">{project.AreaCategoryLabel ?? TEXT.AREA}</span>
                        <span className="value">{project.Area}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectCard;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import About from "./pages/about/About";
import Projects from "./pages/projects/Projects";
import Contact from "./pages/contact/Contact";
import Team from "./pages/team/Team";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import axios from "axios";
import Project from './pages/projects/project/Project';
import ErrorPage from "./pages/util/Error";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    errorElement: <ErrorPage/>,
    children: [
      {
        path: "*",
        element: <About/>
        //todo not found page
      },
      {
        element: <About/>,
        index: true,
      },
      {
        path: "projects",
        element: <Projects/>,
      },
      {
        path: "projects/:id",
        element: <Project />
      },
      {
        path: "contact",
        element: <Contact/>,
      },
      {
        path: "team",
        element: <Team/>,
      }
    ]
  }
])
axios.defaults.baseURL = 'https://panel.emkaa.pl';
const queryClient = new QueryClient({});
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router}/>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

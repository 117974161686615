import React, {useEffect, useState} from "react";
import "./LogoBars.css";
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import LoadingPage from "../../../util/Loading";
import getUsersLanguage from "../../../../langaugeUtil";
import getText from "../../../../staticText";
import { FALSE } from "sass";
import { useNavigate } from "react-router-dom";

const LogoBars = () => {

  const language = getUsersLanguage().toLowerCase();
  const TEXT = getText(language);

  const {isLoading, error, data, isFetched} =
    useQuery({
      queryKey: ["slideshow"],
      queryFn: async () => {
        const response = await axios.get(
          //query generated from https://docs.strapi.io/dev-docs/api/rest/interactive-query-builder
          `/api/text?populate[slideshow_architecture][fields][0]=url&populate[slideshow_facades][fields][0]=url&populate[slideshow_interiors][fields][0]=url&locale=${language}`
        );
        return response.data.data; //"data" field from strapi response
      }
    });



  const baseUrl = axios.defaults.baseURL;
  const [currentImageUrl, setCurrentImageUrl] = useState({url: 'sample1.jpg', index: 1});
  const [requestedImageUrl, setRequestedImageUrl] = useState({url: 'sample1.jpg', index: 1});

  const [isShifting, setIsShifting] = useState(false);
  const [shiftingTimeout, setShiftingTimeout] = useState<any>();

  const navigate = useNavigate();

  useEffect(() => {
    if(isFetched) {
      setCurrentImageUrl({url: `${baseUrl}${data.slideshow_architecture.url}`, index: 1})
      setRequestedImageUrl({url: `${baseUrl}${data.slideshow_architecture.url}`, index: 1})
    }
  }, [isFetched])

  const transitionTo = (url: string, index: number) => {
    const targetImageUrl = url;
    if (targetImageUrl !== requestedImageUrl.url) {
      setRequestedImageUrl({url: targetImageUrl, index: index});
      clearTimeout(shiftingTimeout);
      setIsShifting(true);
      const tid = setTimeout(() => {
        setCurrentImageUrl({url: targetImageUrl, index: index});
        setIsShifting(false);
        setShiftingTimeout(undefined);
      }, 200);
      setShiftingTimeout(tid);
    }
  };

  const barClicked = (category: string) => {
    navigate('/projects?category=' + category)
  }

  let imgClasses = "background-image";
  if (isShifting) {
    imgClasses += " fade-out";
  }
  if(isLoading){
    return (
      <LoadingPage />
    )
  }

  return (
    <div className="background-container">
      <img
        src={currentImageUrl.url}
        id="backgroundImage"
        alt="Background"
        className={imgClasses}
      />
      <div
        id="backgroundImage2"
        className="background-filter"
      />

      <div className="centered-bars">
        <svg className="bar" xmlns="http://www.w3.org/2000/svg" width="262.49" height="73.444"
             viewBox="0 0 262.49 73.444">
          <path id="Path_19" data-name="Path 19"
                d="M251.243.027,9.751,17.277A10.5,10.5,0,0,0,0,27.75V45.7A10.5,10.5,0,0,0,9.751,56.168l241.492,17.25A10.5,10.5,0,0,0,262.49,62.944V10.5A10.5,10.5,0,0,0,251.243.027"
                transform="translate(0 0)" fill={requestedImageUrl.index === 1 ? "black" : "white"}
                onMouseOver={() => isFetched ? transitionTo(`${baseUrl}${data.slideshow_architecture.url}`, 1) : transitionTo('sample1.jpg', 1)}
                onClick={() => barClicked("architecture") }/>
        </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="230" height="48" viewBox="0 0 230 48">
              <text id="architektura" transform="translate(0 39)" fill="#242425" fontSize="36" opacity={requestedImageUrl.index === 1 ? "1" : "0"}
                    fontFamily="SegoeUIBlack, Segoe UI">
                <tspan x="0" y="0">{TEXT.ARCHITECTURE.toLocaleLowerCase()}</tspan>
              </text>
            </svg>
        <svg className="bar" xmlns="http://www.w3.org/2000/svg" width="262.49" height="73.445"
             viewBox="0 0 262.49 73.445">
          <path id="Path_21" data-name="Path 21"
                d="M11.247,50.645,252.74,67.9a10.5,10.5,0,0,1,9.751,10.473V96.314a10.5,10.5,0,0,1-9.751,10.471L11.247,124.035A10.5,10.5,0,0,1,0,113.562V61.119A10.5,10.5,0,0,1,11.247,50.645"
                transform="translate(0 -50.618)" fill={requestedImageUrl.index === 2 ? "black" : "white"}
                onMouseOver={() => isFetched ? transitionTo(`${baseUrl}${data.slideshow_facades.url}`, 2) : transitionTo('sample2.jpg', 2)}
                onClick={() => barClicked("facades") }
                />
        </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="217" height="48" viewBox="0 0 217 48">
              <text id="fasady" transform="translate(0 39)" fill="#242425" fontSize="36" opacity={requestedImageUrl.index === 2 ? "1" : "0"}
                    fontFamily="SegoeUIBlack, Segoe UI">
                <tspan x="0" y="0">{TEXT.FACADES.toLocaleLowerCase()}</tspan>
              </text>
            </svg>
        <svg className="bar" xmlns="http://www.w3.org/2000/svg" width="262.49" height="73.444"
             viewBox="0 0 262.49 73.444">
          <path id="Path_19" data-name="Path 19"
                d="M251.243.027,9.751,17.277A10.5,10.5,0,0,0,0,27.75V45.7A10.5,10.5,0,0,0,9.751,56.168l241.492,17.25A10.5,10.5,0,0,0,262.49,62.944V10.5A10.5,10.5,0,0,0,251.243.027"
                transform="translate(0 0)" fill={requestedImageUrl.index === 3 ? "black" : "white"}
                onMouseOver={() => isFetched ? transitionTo(`${baseUrl}${data.slideshow_interiors.url}`, 3) : transitionTo('sample3.jpg', 3)}
                onClick={() => barClicked("interiors") }/>
        </svg>

            <svg xmlns="http://www.w3.org/2000/svg" width="160" height="48" viewBox="0 0 160 48">
              <text id="wnętrza" transform="translate(0 39)" fill="#242425" fontSize="36" opacity={requestedImageUrl.index === 3 ? "1" : "0"}
                    fontFamily="SegoeUIBlack, Segoe UI">
                <tspan x="0" y="0">{TEXT.INTERIORS.toLocaleLowerCase()}</tspan>
              </text>
            </svg>
        <svg className="company-title-slideshow" xmlns="http://www.w3.org/2000/svg" width="260.577" height="17.659" viewBox="0 0 260.577 17.659">
          <g id="Group_53" data-name="Group 53" transform="translate(18338.838 8450.228)">
            <path id="Path_22" data-name="Path 22"
                  d="M12.91,164.88H3.869a3.187,3.187,0,0,0,3.421,2.4,4.048,4.048,0,0,0,3-1.156l1.843,2A6.261,6.261,0,0,1,7.2,170.057c-4.128,0-6.815-2.6-6.815-6.152a6.082,6.082,0,0,1,6.373-6.152,5.914,5.914,0,0,1,6.217,6.2c0,.267-.043.645-.065.932M3.826,162.86H9.712a2.839,2.839,0,0,0-2.93-2.487,2.828,2.828,0,0,0-2.956,2.487"
                  transform="translate(-18339.223 -8602.626)" fill="#fff"/>
            <path id="Path_23" data-name="Path 23"
                  d="M30.871,163.038v6.841H27.406v-6.308c0-1.934-.867-2.821-2.267-2.821-1.554,0-2.641,1-2.641,3.132v6H19.031v-6.308c0-1.934-.82-2.821-2.265-2.821-1.532,0-2.621,1-2.621,3.132v6H10.68V157.928h3.31v1.378a4.834,4.834,0,0,1,3.754-1.554,4.453,4.453,0,0,1,3.93,1.954,5.376,5.376,0,0,1,4.31-1.954c2.843,0,4.887,1.643,4.887,5.286"
                  transform="translate(-18330.451 -8602.626)" fill="#fff"/>
            <path id="Path_24" data-name="Path 24"
                  d="M30.643,167.2l-1.665,1.645v3.043H25.512V155.4h3.467v9.329l5.063-4.8h4.132L33.2,165l5.419,6.886h-4.2Z"
                  transform="translate(-18317.814 -8604.629)" fill="#fff"/>
            <path id="Path_25" data-name="Path 25"
                  d="M46.487,163.06v6.819H43.244V168.39a3.921,3.921,0,0,1-3.643,1.667c-2.8,0-4.465-1.556-4.465-3.621,0-2.109,1.489-3.576,5.132-3.576h2.754c0-1.487-.887-2.354-2.754-2.354a5.743,5.743,0,0,0-3.443,1.111l-1.245-2.422a9.2,9.2,0,0,1,5.154-1.443c3.641,0,5.752,1.687,5.752,5.308M43.022,166.1V164.88H40.646c-1.621,0-2.134.6-2.134,1.4,0,.867.733,1.443,1.956,1.443a2.551,2.551,0,0,0,2.554-1.62"
                  transform="translate(-18309.613 -8602.626)" fill="#fff"/>
            <path id="Path_26" data-name="Path 26"
                  d="M56.134,163.06v6.819H52.891V168.39a3.921,3.921,0,0,1-3.643,1.667c-2.8,0-4.465-1.556-4.465-3.621,0-2.109,1.489-3.576,5.132-3.576h2.754c0-1.487-.887-2.354-2.754-2.354a5.743,5.743,0,0,0-3.443,1.111l-1.245-2.422a9.2,9.2,0,0,1,5.154-1.443c3.641,0,5.752,1.687,5.752,5.308M52.669,166.1V164.88H50.293c-1.621,0-2.134.6-2.134,1.4,0,.867.733,1.443,1.956,1.443a2.551,2.551,0,0,0,2.554-1.62"
                  transform="translate(-18301.395 -8602.626)" fill="#fff"/>
            <path id="Path_27" data-name="Path 27"
                  d="M70.98,163.06v6.819H67.737V168.39a3.921,3.921,0,0,1-3.643,1.667c-2.8,0-4.465-1.556-4.465-3.621,0-2.109,1.489-3.576,5.132-3.576h2.754c0-1.487-.887-2.354-2.754-2.354a5.743,5.743,0,0,0-3.443,1.111l-1.245-2.422a9.2,9.2,0,0,1,5.154-1.443c3.641,0,5.752,1.687,5.752,5.308M67.515,166.1V164.88H65.139c-1.621,0-2.134.6-2.134,1.4,0,.867.733,1.443,1.956,1.443a2.551,2.551,0,0,0,2.554-1.62"
                  transform="translate(-18288.746 -8602.626)" fill="#fff"/>
            <path id="Path_28" data-name="Path 28"
                  d="M77.116,157.752v3.2c-.287-.022-.509-.044-.778-.044-1.909,0-3.176,1.045-3.176,3.332v5.641H69.7V157.93h3.31v1.576a4.828,4.828,0,0,1,4.11-1.754"
                  transform="translate(-18280.168 -8602.626)" fill="#fff"/>
            <path id="Path_29" data-name="Path 29"
                  d="M76.527,163.9c0-3.6,2.776-6.152,6.664-6.152,2.509,0,4.487,1.087,5.352,3.043l-2.687,1.443a2.98,2.98,0,0,0-2.687-1.643,3.315,3.315,0,0,0,0,6.619,2.939,2.939,0,0,0,2.687-1.644l2.687,1.467a5.543,5.543,0,0,1-5.352,3.021c-3.887,0-6.664-2.554-6.664-6.152"
                  transform="translate(-18274.348 -8602.626)" fill="#fff"/>
            <path id="Path_30" data-name="Path 30"
                  d="M98.512,165.041v6.841H95.047v-6.308c0-1.933-.889-2.821-2.422-2.821-1.665,0-2.865,1.02-2.865,3.219v5.91H86.294V155.4h3.465v5.775a5.253,5.253,0,0,1,3.8-1.42c2.822,0,4.954,1.643,4.954,5.286"
                  transform="translate(-18266.027 -8604.629)" fill="#fff"/>
            <path id="Path_31" data-name="Path 31"
                  d="M96.6,156.795a1.981,1.981,0,0,1,2.154-1.934,1.945,1.945,0,0,1,2.154,1.867,2.163,2.163,0,0,1-4.308.067m.42,3.6h3.465v11.951H97.023Z"
                  transform="translate(-18257.242 -8605.089)" fill="#fff"/>
            <path id="Path_32" data-name="Path 32"
                  d="M111.048,170.436a4.948,4.948,0,0,1-2.821.756c-2.823,0-4.465-1.445-4.465-4.287V162h-1.845v-2.667h1.845V156.42h3.465v2.909H110.2V162h-2.976v4.863a1.394,1.394,0,0,0,1.487,1.578,2.333,2.333,0,0,0,1.4-.444Z"
                  transform="translate(-18252.715 -8603.761)" fill="#fff"/>
            <path id="Path_33" data-name="Path 33"
                  d="M121.993,164.88h-9.042a3.187,3.187,0,0,0,3.421,2.4,4.043,4.043,0,0,0,3-1.156l1.845,2a6.258,6.258,0,0,1-4.932,1.932c-4.13,0-6.817-2.6-6.817-6.152a6.083,6.083,0,0,1,6.375-6.152,5.914,5.914,0,0,1,6.217,6.2c0,.267-.041.645-.065.932m-9.082-2.021h5.884a2.984,2.984,0,0,0-5.884,0"
                  transform="translate(-18246.283 -8602.626)" fill="#fff"/>
            <path id="Path_34" data-name="Path 34"
                  d="M124.893,167.2l-1.665,1.645v3.043h-3.467V155.4h3.467v9.329l5.063-4.8h4.132L127.449,165l5.419,6.886h-4.2Z"
                  transform="translate(-18237.512 -8604.629)" fill="#fff"/>
            <path id="Path_35" data-name="Path 35"
                  d="M129.17,163.9c0-3.6,2.776-6.152,6.664-6.152,2.509,0,4.487,1.087,5.352,3.043l-2.687,1.443a2.98,2.98,0,0,0-2.687-1.643,3.315,3.315,0,0,0,0,6.619,2.939,2.939,0,0,0,2.687-1.644l2.687,1.467a5.543,5.543,0,0,1-5.352,3.021c-3.887,0-6.664-2.554-6.664-6.152"
                  transform="translate(-18229.496 -8602.626)" fill="#fff"/>
            <path id="Path_36" data-name="Path 36"
                  d="M138.758,156.795a1.981,1.981,0,0,1,2.154-1.934,1.945,1.945,0,0,1,2.154,1.867,2.163,2.163,0,0,1-4.308.067m.422,3.6h3.465v11.951H139.18Z"
                  transform="translate(-18221.326 -8605.089)" fill="#fff"/>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default LogoBars;

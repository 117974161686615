import './Footer.css';
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import LoadingPage from '../pages/util/Loading';
import getUsersLanguage from '../langaugeUtil';
import getText from '../staticText';

const Footer = () => {
  const language = getUsersLanguage().toLowerCase();
  const TEXT = getText(language);

  const {isLoading, error, data, isFetched} =
    useQuery({
      queryKey: ["footer"],
      queryFn: async () => {
        const response = await axios.get(
          //query generated from https://docs.strapi.io/dev-docs/api/rest/interactive-query-builder
          `/api/text`
        );
        return response.data.data; //"data" field from strapi response
      }
    });

    if(isLoading){
      return (
        <LoadingPage />
      )
    }

  return (
    <footer>
      {/*TODO add scroll-to-top button*/}

      <div className="footer-justified-container">
        <span>{TEXT.PRIVACY_POLICY}</span>
        <div className="footer-content">
          <div className="footer-links">
            <a href="https://linkedin.com/company/emkaa-architekci" target="_blank" rel="noreferrer">
              <svg id="Warstwa_11" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 90.2 80.7"
                   fill="#FFF">
                <path fill="#FFF"
                      d="M68.9,12.77c2.45.54,4.28,2.37,4.82,4.82v47.72c-.55,2.5-2.52,4.49-5.1,4.82-15.99-.11-32.04.22-48-.17-2.27-.65-4.03-2.66-4.26-5.04V17.87c.19-2.34,2.5-4.8,4.82-5.1h47.72ZM32.9,22c-2.5-2.5-7.18-1.62-8.42,1.76-1.58,4.33,2.44,8.34,6.77,6.66,3.37-1.3,4.16-5.9,1.64-8.42ZM65.09,61.51v-17.31c0-1.02-.87-3.18-1.41-4.08-3.37-5.63-10.72-6.72-15.23-1.69-.21.24-.31.7-.62.67v-3.36h-8.51v25.77h8.51v-15.18c0-2.25,2.19-4.35,4.43-4.32s4.31,2.12,4.31,4.32v15.18h8.51ZM33.5,35.74h-8.51v25.77h8.51v-25.77Z"/>
              </svg>
            </a>
            <a href="https://www.facebook.com/emkaarchitekci" target="_blank" rel="noreferrer">
              <svg id="Warstwa_12" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 90.2 80.7"
                   fill="#FFF">
                <path fill="#FFF"
                      d="M66.61,13.27H23.59c-3.96,0-7.17,3.16-7.17,7.05v42.29c0,3.89,3.21,7.05,7.17,7.05h23.47v-24.19h-3.75v-7.66h3.75v-4.88c0-6.39,2.49-10.19,9.58-10.19h7.18v7.63h-4.18c-2.75,0-2.94,1.09-2.94,3.14v4.31s6.56,0,6.56,0l-.79,7.66h-5.78v24.19h9.91c3.96,0,7.17-3.15,7.17-7.05V20.32c0-3.89-3.21-7.05-7.17-7.05"/>
              </svg>
            </a>
            <a href="https://www.instagram.com/emkaa_architekci" target="_blank" rel="noreferrer">
              <svg id="Warstwa_13" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 90.2 80.7"
                   fill="#FFF">
                <path fill="#FFF"
                      d="M57.71,69.18h-25.22c-8.83,0-16.01-6.95-16.01-15.5v-24.42c0-8.55,7.18-15.5,16.01-15.5h25.22c8.82,0,16.01,6.95,16.01,15.5v24.42c0,8.54-7.18,15.5-16.01,15.5M32.49,18.65c-6.04,0-10.97,4.76-10.97,10.62v24.42c0,5.85,4.92,10.62,10.97,10.62h25.22c6.04,0,10.96-4.76,10.96-10.62v-24.42c0-5.85-4.92-10.62-10.96-10.62h-25.22Z"/>
                <path fill="#FFF"
                      d="M45.1,54.37c-7.34,0-13.32-5.79-13.32-12.9s5.98-12.89,13.32-12.89,13.32,5.78,13.32,12.89-5.97,12.9-13.32,12.9M45.1,33.46c-4.56,0-8.27,3.59-8.27,8.01s3.71,8.01,8.27,8.01,8.27-3.59,8.27-8.01-3.71-8.01-8.27-8.01"/>
                <path fill="#FFF"
                      d="M63.3,25.94c0,1.98-1.65,3.58-3.7,3.58s-3.7-1.6-3.7-3.58,1.66-3.58,3.7-3.58,3.7,1.6,3.7,3.58"/>
              </svg>
            </a>
          </div>
          <h3 className='footer-title'>emkaa architekci Sp. z o.o.</h3>
          <div className="footer-address-container">
            <span>{isFetched ? data.address : "ul. Kobielska 23/217, 04-359 Warszawa"}</span>
          </div>

        </div>
        <span>Copyright © 2024</span>
      </div>
    </footer>
  )
}

export default Footer;
import {useState} from "react"
import ProjectCard from "./projectCard/ProjectCard"
import "./Projects.css"
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import LoadingPage from "../util/Loading";
import ProjectInterface from "../../interfaces/ProjectInterface";
import getUsersLanguage from "../../langaugeUtil";
import getText from "../../staticText";
import { useSearchParams } from "react-router-dom";

const Projects = () => {

  const language = getUsersLanguage().toLowerCase();
  const TEXT = getText(language);

  let [searchParams, setSearchParams] = useSearchParams();

  const {isLoading, error, data, isFetched} =
  useQuery({
    queryKey: ["projects", searchParams.get("category")],
    queryFn: async () => {
      const category = searchParams.get("category")
      const URL = category !== null ? 
        `/api/projects?populate[Images][fields][0]=url&sort=rank:asc&pagination[pageSize]=100&locale=${language}&filters[design_category][$eqi]=${category}` :
        `/api/projects?populate[Images][fields][0]=url&sort=rank:asc&pagination[pageSize]=100&locale=${language}`

      const response = await axios.get(
        //query generated from https://docs.strapi.io/dev-docs/api/rest/interactive-query-builder
        URL
      );
      return response.data.data; //"data" field from strapi response
    }
  });

  if(isLoading){
    return (
      <LoadingPage />
    )
  }

  return (
    <div className="projects-container">
      <div className="panel-section">
        {data && data.map((p: ProjectInterface) => (
          <ProjectCard key={`project#${p.documentId}`} project={p}/>
        ))}
      </div>
    </div>
  )
}



export default Projects;
import axios from 'axios';
import './TeamMemberCard.css';
import TeamMember from '../../../interfaces/TeamMemberInterface';

interface Props {
  member: TeamMember
}

const TeamMemberCard = (props: Props) => {

  const member = props.member;

  const baseUrl = axios.defaults.baseURL;

  return (
    <div className="team-member-panel">
      <img src={`${baseUrl}${member.Image.formats?.large?.url ?? member.Image.url}`} alt="Team member"/>
      <div className="member-info-bar">
        <span className="team-member-name">{member.Name}</span>
      </div>
    </div>
  )
}

export default TeamMemberCard;
import React, {useRef} from 'react';
import './App.css';
import Navbar from './components/Navbar';
import {Outlet} from "react-router-dom";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";

function App() {

  return (
    <div id="app_element" className="App">
      <ScrollToTop smooth={true}/>
      <Navbar/>
      <Outlet/>
      <Footer/>
    </div>
  );
}

export default App;

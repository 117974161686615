import { useNavigate } from 'react-router-dom';
import './BottomNav.css';

interface Props {
  previous: string | null,
  next: string | null
}

const BottomNav = (props: Props) => {

  

  const next = props.next;
  const prev = props.previous;

  const navigate = useNavigate();

  const onProjectClick = (id: string) => {
    navigate(`/projects/${id}`)
}

  return (
    <div className="project-nav">
      <span style={{visibility: prev ? "visible" : "hidden"}} onClick={() => onProjectClick(prev!)} className="prev-project">Poprzedni projekt</span>
      <span style={{visibility: next ? "visible" : "hidden"}} onClick={() => onProjectClick(next!)} className="next-project">Następny projekt</span>
    </div>
  );
}

export default BottomNav;
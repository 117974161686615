const getUsersLanguage = () => {
  const AVAILABLE_LANGUAGES = ["PL", "EN"]

  const lan = localStorage.getItem("site_language");
  if (lan) {
    const savedLanguage = JSON.parse(lan);
    if (AVAILABLE_LANGUAGES.includes(savedLanguage)) {
      return savedLanguage;
    }
  }
  //for fake loader, amazing code
  setTimeout(() => localStorage.setItem("site_language", JSON.stringify("PL")), 1000);
  return "PL";
}


export default getUsersLanguage;
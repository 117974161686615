const StaticText = {
    en: {
        ABOUT_US: "ABOUT US",
        PROJECTS: "Projects",
        TEAM: "Team",
        CONTACT: "Contact",
        WORKING_SINCE: "Operating since",
        YEAR: "",
        CLIENTS: "Our clients",
        ARCHITECTURE: "Architecture",
        FACADES: "Facades",
        INTERIORS: "Interiors",
        PRIVACY_POLICY: "Privacy Policy",
        CATEGORY: "Category",
        LOCATION: "Location",
        YEAR_2: "Year",
        CLIENT: "Client",
        AREA: "Area",
        CONCEPT: "CONCEPT",
    },
    pl: {
        ABOUT_US: "O NAS",
        PROJECTS: "Projekty",
        TEAM: "Zespół",
        CONTACT: "Kontakt",
        WORKING_SINCE: "Działamy już od",
        YEAR: "roku",
        CLIENTS: "Nasi klienci",
        ARCHITECTURE: "Architektura",
        FACADES: "Fasady",
        INTERIORS: "Wnętrza",
        PRIVACY_POLICY: "Polityka prywatności",
        CATEGORY: "Kategoria",
        LOCATION: "Lokalizacja",
        YEAR_2: "Rok",
        CLIENT: "Klient",
        AREA: "Przestrzeń",
        CONCEPT: "Koncepcja"
    }
} as const;

type Language = keyof typeof StaticText;

const getText = (language: Language) => {
    return StaticText[language];
}

export default getText;

// const language = getUsersLanguage().toLowerCase();
// const TEXT = getText(language);
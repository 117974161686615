import styles from "./Loading.module.css";

const LoadingPage = () => {


    return (
        <div className={styles.background}>
            <img className={styles.gif} src="/calosc.gif" alt="ładowanko" />
        </div>
    )

}

export default LoadingPage;